import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetWhatWeDo = () => {
  const data = useStaticQuery(graphql`
    query getWhatWeDo {
      allStrapiWhatWeDo {
        nodes {
          locale
          Title
          Headertitle
          Button
          Description
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiWhatWeDo);
};
