import ServicesComponent from "../components/services/services";
import React from "react";

import { GetTopics } from "../queries/getTopics";
import { GetWhatWeDo } from "../components/sections/services-section/what-we-do-query";


const Services = () => {
  const topics = GetTopics();
  const whatWeDo = GetWhatWeDo();
  return (
    <ServicesComponent
      servicesList={topics}
      hero={whatWeDo}
      description={whatWeDo.Description}
    />
  );
};

export default Services;

