import * as React from "react";
import Image from "../image/image";
import {
  heroImage,
  heroItem,
  heroWrapper,
  pageHeader,
  title,
  titleContainer,
  heroLogo,
} from "./hero.module.scss";

const Hero = ({ data, isPageHeader = false }) => {
  return (
    <div className={heroWrapper}>
      <div
        key={data.Title}
        className={`${heroItem} ${isPageHeader ? pageHeader : ""}`}
      >
        <div className={titleContainer}>
          <h3 className={title}>
            {data.Headertitle ? data.Headertitle : data.Title}
          </h3>
          <Image
            gatsbyImage={data.Icon}
            loading={"eager"}
            alt={data.Image?.alternativeText ?? data.Title}
            className={heroLogo}
          ></Image>
        </div>
        <Image
          gatsbyImage={data.Image}
          loading={"eager"}
          alt={data.Image?.alternativeText ?? data.Title}
          className={heroImage}
        ></Image>
      </div>
    </div>
  );
};

export default Hero;
