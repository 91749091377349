// extracted by mini-css-extract-plugin
export var dBlock = "services-module--d-block--7ed10";
export var dFlex = "services-module--d-flex--6b008";
export var dInline = "services-module--d-inline--f8095";
export var dLgBlock = "services-module--d-lg-block--b2208";
export var dLgFlex = "services-module--d-lg-flex--e894a";
export var dLgInline = "services-module--d-lg-inline--f1d61";
export var dLgNone = "services-module--d-lg-none--18f38";
export var dMdBlock = "services-module--d-md-block--b46f5";
export var dMdFlex = "services-module--d-md-flex--ccafc";
export var dMdNone = "services-module--d-md-none--c891f";
export var dNone = "services-module--d-none--68c07";
export var heroLogo = "services-module--heroLogo--50ba5";
export var service = "services-module--service--5162d";
export var serviceImg = "services-module--serviceImg--8dfeb";
export var serviceTxt = "services-module--serviceTxt--9a2bd";
export var servicesWrapper = "services-module--servicesWrapper--f3025";
export var siteDesc = "services-module--siteDesc--8431a";