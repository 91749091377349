import React, { useState } from "react";
import {
  servicesWrapper,
  service,
  serviceImg,
  serviceTxt,
  siteDesc,
  heroLogo,
} from "./services.module.scss";
import Image from "../image/image";
import Hero from "../hero/hero";

const ServicesComponent = ({ servicesList, hero, description, linkState }) => {
  const [services] = useState(servicesList);
  return (
    <div>
      <Hero data={hero} isPageHeader={true} />
      <section>
        <p
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-duration="800"
          className={siteDesc}
        >
          {description}
        </p>
        <div className={servicesWrapper}>
          {services?.map((entry, index) => (
            <div className={service} key={entry.Title}>
              {index % 2 > 0 && index < services?.length - 1 ? (
                <Image
                  gatsbyImage={hero.Icon}
                  loading={"lazy"}
                  alt={hero.Image?.alternativeText ?? "Icon"}
                  className={heroLogo}
                ></Image>
              ) : null}
              <Image
                gatsbyImage={entry.Image}
                className={serviceImg}
                alt="powerfusion"
              />
              <div className={serviceTxt}>
                <h2
                  data-sal={index % 2 > 0 ? "slide-right" : "slide-left"}
                  data-sal-delay="100"
                  data-sal-duration="500"
                >
                  {entry.Title}
                </h2>
                <p
                  data-sal={index % 2 > 0 ? "slide-right" : "slide-left"}
                  data-sal-delay="100"
                  data-sal-duration="500"
                >
                  {entry.Description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ServicesComponent;
